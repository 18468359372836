.NotifItem {
  max-width: 100%;
  overflow: hidden;
  display: flex;

  &__content {
    flex: 1
  }

  &__title {
    font-size: 12px;
    font-weight: bold;
  }

  &__description {
    font-size: 12px;
    opacity: 0.7;
  }

  &__image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #ddd;
    margin-right: 8px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &--disabled {
    // background-color: #ddd;
  }
}