@media print {
  body {
    * {
      visibility: hidden;
    }
  }
  #section-to-print {
    visibility: visible;
    position: relative;
    bottom: 130px;
    * {
      visibility: visible;
    }
  }
}
