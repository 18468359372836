.m-custom-collapse {
  &__header {
    padding: 0 14px;
    transition: .3s ease;
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #eee;
    cursor: pointer;

    &:hover {
      background-color: #eee;
    }
  }

  &__body {}
}