$customSelectDropdown: m-select-dropdown;

.#{$customSelectDropdown} {
  position: relative;

  &__selected-wrapper {
    position: relative;
    padding: 8px 14px;
    background-color: #f0f3f5;
    border-radius: 6px;
    border: 1px solid #c8ced3;
    cursor: pointer;
    transition: .3s;
  }

  &__selected-arrow {
    position: absolute;
    right: 14px;
    top: 8px;
  }

  &__option-wrapper {
    position: absolute;
    background-color: #f0f3f5;
    transform: translateY(6px);
    border-radius: 6px;
    z-index: 10;
    width: 100%;
    max-height: 240px;
    overflow-y: auto;
    border: 1px solid #c8ced3;
    overflow-x: hidden;
  }

  &__option-item {
    padding: 14px;
    border-bottom: 1px solid #c8ced3;
    cursor: pointer;
    transition: .1s;

    &:hover {
      background-color: #20a8d8;
      color: #fff;
    }
  }
}
