// Here you can add other styles
@import './components/index';

html:not([dir="rtl"]) .sidebar {
  margin-left: -250px;
}

.app-body .sidebar {
  width: 250px;
}

.sidebar .sidebar-nav {
  width: 250px;
}

.sidebar .nav {
  width: 250px;
}






@media (min-width: 992px) {
  .sidebar-fixed .sidebar {
    width: 250px;
  }

  html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .main {
    margin-left: 250px;
  }
}

@media (min-width: 768px) {
  html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .main {
    margin-left: 250px;
  }
}

@media (min-width: 576px) {
  html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .main {
    margin-left: 250px;
  }
}