//  IMPORT COMPONENTS SCSS

// 1. ATOM COMPONENTS
// =============================
@import './atoms/CustomLoading';

// 2. MOLECULE COMPONENTS
// =============================
@import './molecules/SelectItem';
@import './molecules/CustomSelectDropdown';
@import './molecules/GalleryImageItem';
@import './molecules/CustomCollapse';
@import './molecules/NotifItem';
@import './molecules/KopSurat';


// 3. ORGANISM COMPONENTS
// =============================
@import './organisms/FormChangePhoto';
@import './organisms/EditorSection';
@import './organisms/GallerySection';

// 4. PAGE COMPONENTS
// =============================
@import './pages/MainMenu';


// 5. ADDITIONAL COMPONENTS
// =============================
.w-100px {
  width: 180px;
}