.KopSurat {
  &__logo {
    position: absolute;
    top: 0;
    left: 0;
  }

  &__content {
    position: relative;
    z-index: 1;
  }
}