$gallerySection: o-gallery-section;
.#{$gallerySection} {
  &__upload-wrapper {
    position: relative;
    height: 200px;
    background-color: rgba(0,0,0,0.1);
    overflow: hidden;
    border: 2px solid rgba(0,0,0,0.2);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__upload-input {
    position: absolute;
    top:0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
    opacity: 0;
    cursor: pointer;
  }

  &__upload-label {
    display: inline-block;
    padding: 8px 14px;
    background-color: #ccc;
    border-radius: 8px;
  }
}