.a-custom-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999999999;
  background-color: rgba(255,255,255,0.5);

  &__center-content {
    max-width: 150px;
    text-align: center;
  }
  
  &__image {
    width: 100%
  }
}