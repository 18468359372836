$galleryImageItem: m-gallery-image-item;
.#{$galleryImageItem} {
  position: relative;
  overflow: hidden;
  padding-bottom: 100%;
  background-size: cover;
  background-position: center;
  border-width: 1px;
  border-color: rgba(0,0,0,0.5);
  border-style: solid;

  &__image-title {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 14px;
    display: inline-block;
    width: 100%;
    background-color: rgba(0,0,0,0.5);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    transition: 3s;
    color: white;
  }

  &__action {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    background-color: rgba(0,0,0,0.5);
    padding: 14px;
    text-align: center;
    transition: 0.3s;
    transform: translateY(100%);
  }

  &:hover {
    .#{$galleryImageItem}__action {
      transform: translateY(0);
    }
  }
}