$selectItem: m-select-item;
.#{$selectItem} {
  &__wrapper {
    padding: 14px;
    background-color: #f5f5f5;
    max-height: 200px;
    overflow-y: auto;
    border-radius: 6px;
    border: 1px solid #e8e8e8;
  }
  span {
    vertical-align: middle;
    cursor: pointer;
  }
  &__icon {
    opacity: 0.4;
    font-size: 24px;

    &.active {
      opacity: 1;
      color: #20a8d8;
    }
  }
  &__title {
    opacity: 0.8;
  }
}
