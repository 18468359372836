.o-form-change-photo {
  $rootClass: o-form-change-photo;

  &__image-container {
    position: relative;
    overflow: hidden;
    border-radius: 100px;
    width: 200px;
    height: 200px;
    border: 2px solid #ddd;
    margin: 0 auto 24px auto;

    img {
      height: 45vh;
      border-radius: 100px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:hover {
      .#{$rootClass}__image-input {
        transform: translateY(0);
      }
    }
  }

  &__image-input {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 14px;
    text-align: center;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.4);
    transition: 0.3s ease;
    transform: translateY(100%);

    input {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      opacity: 0;
      z-index: 1;
      cursor: pointer;
    }
  }
}